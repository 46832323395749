// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import { Dropzone } from "dropzone";
import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

require("dropzone");

import "popper.js"
window.Popper = require('popper.js').default;

Rails.start()
Turbolinks.start()
ActiveStorage.start()

window.jQuery = $;
window.$ = $;
global.Rails = Rails;
//require('jquery')

import "bootstrap"
import { Tooltip, Popover, Dropdown, bootstrap } from "bootstrap/dist/js/bootstrap.bundle.js"
document.addEventListener("turbolinks:load", () => {
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map(function(tooltipTriggerEl) {
        return new Tooltip(tooltipTriggerEl)
    })

    var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
    var popoverList = popoverTriggerList.map(function(popoverTriggerEl) {
        return new Popover(popoverTriggerEl)
    })

    var dropdownTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="dropdown"]'))
    var dropdownList = dropdownTriggerList.map(function (dropdownTriggerEl) {
        return new Dropdown(dropdownTriggerEl)
    })
})

import select2 from 'select2';
import 'select2/dist/css/select2.css';
window.select2 = select2;

import "smooth-scroll"

import moment from 'moment';
window.moment = moment;

import { Calendar, FullCalendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
window.Calendar = Calendar;

import Typed from 'typed.js';
window.Typed = Typed;

require('jkanban');

import flatpickr from "flatpickr";
window.flatpickr = flatpickr;

import './scripts.bundle'
import './style.dark.bundle'

import Swal from 'sweetalert2'
import 'sweetalert2/dist/sweetalert2.css'
window.Swal = Swal;



import toastr from 'toastr'
import 'toastr'
window.toastr = toastr;


import dt from 'datatables.net-bs';
window.dt = dt;

global.Dropzone = Dropzone;